.navbar {
    position: fixed;
    top: 4%;
    left: 0%;
    right: 0%;
    width: 100%;
    height: 30px;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease, width 0.3s ease;
    padding: 10px 0px;
    background-color: transparent;

}

.navbar-container {

}
.navbar.scrolled {
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    background-color: rgba(114,114,114,.2);
    border-radius: 30px; /* Gives rounded corners */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow */
    width: fit-content; /* Adjust width to not stretch full page */
    margin: 0 auto;
    padding: 10px 40px;
    border: 1px solid #404040b5;
}

.nav-menu {
    list-style: none;
    display: flex;
    gap: 20px;
    border-radius: 50px;
    padding: 10px 20px;
    margin: 0; /* Center the menu in the navbar */
}

.nav-item {
    /* No changes required here */
    /*background-color: rgba(255, 255, 255, 0.2); !* Hover and active link styles *!*/
    gap: 50px;
}

.nav-links {
    color: white;
    text-decoration: none;
    padding: 8px 16px;
    border-radius: 20px;
    transition: background 0.3s ease;
}

.nav-links:hover, .active {
    background-color: rgba(255, 255, 255, 0.2); /* Hover and active link styles */
}