@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap'); /* HeroSection.css */
.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height */
    color: #fff; /* Adjust the color to match your design */
    text-align: center;
    padding: 0 20px; /* Padding on the sides if needed */
    font-family: 'Roboto', sans-serif;
}

.hero-section h1 {
    font-size: 4rem;
    font-weight: bold;
    margin: 0;
    line-height: 1.2;
}

.heading{
    font-size: 5rem; /* Increased size for visibility */
    font-weight: 300; /* Bold weight for the main heading */
    margin: 0;
    line-height: 1.2; /* Adjust line height to match your design */
    background: -webkit-linear-gradient(#fff, #a5a5a5); /* Adjust gradient colors as needed */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline;
    /*flex-direction: column;*/
    gap: 0.5rem;
    /*font-family: __inter_Fallback_20b187,ui-sans-serif,system-ui,-apple-system,"system-ui",Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";*/
    font-family: "roboto", sans-serif;
}

/*.hero-section h1 .heading{*/
/*    z-index: 1;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    font-weight: 400;*/
/*    font-size: 75px;*/
/*    margin-bottom: 20px;*/
/*}*/

.firstSpan {
    /*color: #76b900; !* This color is just an example, use color picker for exact match *!*/
    font-size: 1.2em;
    background: -webkit-linear-gradient(#fff,#a5a5a5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    /*font-size: 5em;*/
    /*font-weight: 700;*/
}

.secondSpan{
    font-size: 1.2em;
    background: -webkit-linear-gradient(#58ffb4,#289051);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    align-self: flex-start;
    margin-left: 10%;
    display: table-cell;
}

.thirdSpan {
    font-size: 1.2rem; /* Smaller font size for the paragraph */
    font-weight: 300; /* Lighter font weight for the paragraph */
    color: rgba(255, 255, 255, 0.7); /* Slightly transparent white color */
    max-width: 800px; /* Set a max-width for the content */
    margin-top: 20px; /* Spacing above the paragraph */
    text-align: center; /* Center the text */
    /*letter-spacing: 0.08em;*/
    word-spacing: 0.2em;
}

@media (max-width: 768px) {
    .heading {
        font-size: 3rem; /* Smaller font size on mobile */
    }
    .firstSpan, .secondSpan {
        font-size: 3rem; /* Match the heading size for mobile */
    }
}

.hero-section .subtitle {
    font-size: 2rem; /* Adjust the size to match your design */
    font-weight: lighter; /* Adjust the weight to match your design */
    margin: 20px 0; /* Spacing above and below the subtitle */
}


body{
    letter-spacing: 1px;
    font-weight: 300;
    background-color: var(--black-color);
    color: var(--text-color);
    transition: all .2s ease;
}

:root{
    --green : #40cf8e;
    --text-color: #bbbbbb;
    --white-color: #ededed;
    --black-color: #131313;
}

.about-section {
    max-width: 1320px;
    margin: 0 auto;
    margin-left:80px;
    font-family: 'Inter', sans-serif;
    overflow: hidden;
    padding-right: 60px;
}

.about-title {
    font-size: 1.5rem;
    text-align: left;
    letter-spacing: 0.1em;
    color: #ededed;
}

.about-content {
    font-size: 17px;
    text-align: left;
    line-height: 1.6;
    margin-bottom: 2rem;
    color: rgba(255, 255, 255, 0.7);
    word-spacing: 0.3em;
    word-wrap: break-word;
    overflow-wrap: break-word;

}

.skillset,
.experience {
    text-align: left;
    margin-bottom: 1rem;
}

.skillset-list,
.experience-list {
    list-style: none;
    padding: 0;
}

.skillset-list li,
.experience-list li {
    display: inline-block;
    margin-right: 15px;
}

.skills-section {
    font-family: 'Inter', sans-serif;
    margin-left:20px;
    color: rgba(255, 255, 255, 0.7);

}

.skills-section h2 {
    /*font-size: 24px;*/
    /*text-align: left;*/
    /*color: white;*/
    font-size: 1.5rem;
    text-align: left;
    letter-spacing: 0.1em;
    color: #ededed;
    font-weight: 400;
}

.skills-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    max-width: 1300px;
    padding-bottom: 40px;
}

.skill {
    font-size: 16px;
    font-weight: 100;
    border-radius: 2px;
    border:1px solid #333333;
    background-color: #232323;
    box-shadow: 0 2px 3px #0000003d;
    padding: 5px;
}

.experience-container {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    color: white;
}

.experience-title{
    text-align: left;
    padding-left: 20px;
    font-weight: 400;
    color: #ededed;
}

.left-container {
    padding-left: 5px;
    width: 200px;
}

.right-container {
    flex-grow: 1;
    padding-left: 20px;
    margin-top: -30px;
    text-align: left;
}

.experience-dates {
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 5px;
}

.experience-company {
    font-size: 1.5rem;
    color: var(--white-color);
    /*margin-bottom: 10px;*/
    font-weight: 400;
    padding-top: 13px;
}

.experience-role {
    font-style: italic;
    margin-bottom: 10px;
}

.experience-description {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1.6;
    max-width: 1100px;
    color: rgba(255, 255, 255, 0.7);

}

.experience-skills .skill {
    background-color: #40cf8f0f;
    border: 1px solid var(--green);
    padding: 5px 10px;
    border-radius: 4px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 0.9rem;
    display: inline-block;
    color: var(--green);
}

/* ... other styles ... */

.projects-section {
    color: var(--white-color);
    /*margin-bottom: 2rem;*/
    margin: 50px 0;
}

.projects-title {
    font-size: 24px;
    color: var(--white-color);
    text-align: left;
    padding-left: 20px;
    margin-bottom: 1rem;
    font-weight: 400;
}

.projects-container {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 10px;
    /*padding: 0 20px;*/
    align-items: flex-start;
    padding-left: 20px;

}

.project-link {
    position: absolute;
    top: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    font-size: 20px;
    /*stroke: currentColor;*/
    color: #bbbbbb;
    /*color: --text-color;*/
    /* Add styles for the link or icon */
}

.project-entry {
    position: relative;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    text-align: center;
    padding: 18px;
    border-radius: 5px;
    border: 1px solid #2d2d2d;
    transition: all .2s ease;
    font-size: 14px;
    height: 150px;
    background-color: #1c1c1c;
    box-shadow: 0 2px 3px #0000003d;
    /*width: 100px;*/
    background: radial-gradient(100% 100% at 0% 0%,hsla(0,0%,100%,.03),transparent);
    cursor: pointer;
}

.project-name {
    font-size: 1.2rem;
    /*color: var(--green);*/
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 0.5rem;
    position: center;
}

.project-skills {
    top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 50px;
}

.project-skill {
    /*background-color: #333333;*/
    color: var(--text-color);
    padding: 5px 10px;
    /*border-radius: 4px;*/
    /*font-size: 0.9rem;*/
    /*display: inline-block;*/
}

.blogs-section {
    color: var(--white-color);
    margin-top: 50px; /* Adjust as you see fit */
    /*padding-bottom: 20px;*/
}

.blogs-title {
    font-size: 24px;
    color: var(--white-color);
    text-align: left;
    padding-left: 20px;
    margin-bottom: 20px; /* Adjust as you see fit */
    font-weight: 400;
}

.blogs-container {
    display: grid;
    /*grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); !* This will create a responsive grid *!*/
    /*gap: 20px;*/
    /*padding: 0 20px;*/
    /*display: grid;*/
    grid-template-columns: repeat(2,1fr);
    gap: 10px;
    /*padding: 0 20px;*/
    align-items: flex-start;
    padding-left: 20px;
}

.blog-entry {
    position: relative;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    text-align: center;
    padding: 18px;
    border-radius: 5px;
    border: 1px solid #2d2d2d;
    transition: all .2s ease;
    font-size: 14px;
    height: 150px;
    background-color: #1c1c1c;
    box-shadow: 0 2px 3px #0000003d;
    /*width: 100px;*/
    background: radial-gradient(100% 100% at 0% 0%,hsla(0,0%,100%,.03),transparent);
    cursor: pointer;
}

.blog-title {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.blog-date-readTime {
    color: var(--text-color);
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.blog-description {
    color: var(--text-color);
    font-size: 1rem;
    margin-bottom: 20px;
}

.blog-link {
    font-size: 1.2rem;
    color: var(--green);
    text-decoration: none; /* Optional: removes underline */
}

.footer {
    padding: 20px 0;
    background-color: #131313; /* Adjust to match your design */
    color: var(--text-color);
    text-align: center;
}

.footer-content {
    max-width: 1320px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.footer-links {
    display: flex;
    gap: 10px; /* Adjust the space between icons */
}

.footer-links a {
    color: var(--text-color); /* Adjust to match your design */
    font-size: 1.5rem; /* Adjust size as needed */
    transition: color 0.3s;
}

.footer-links a:hover {
    color: var(--green); /* Color when hovered */
}

.separator-line {
    border-bottom: 1px solid #757573; /*var(--text-color);  This will create a thin line, adjust color as needed */
    margin: 0 auto;
    max-width: 1320px;
    padding-top: 50px;
    box-sizing: content-box;
}

