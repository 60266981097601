.App {
  text-align: center;
  /*background-image: url("./Assets/images/gradient.png");*/
  /*background-attachment: fixed; !* This keeps the background image fixed during scroll *!*/
  /*background-size: cover; !* This will ensure that the background covers the entire viewport *!*/
  /*background-position: center;*/
  /*background: radial-gradient(circle at top, #0f0c29, #302b63, #24243e);*/
  /*background-image: url("./Assets/images/stars.png");*/
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background: radial-gradient(circle at top, #0f0c29, #302b63, #24243e);*/
  /*background-image: url("./Assets/images/gradient.png");*/

  z-index: -1;
  pointer-events: none;
  opacity: var(--scroll-opacity, 1); /* Default opacity is 1 */
  transition: opacity 0.3s ease; /* Smooth transition for opacity change */
}
/*body.scrolled::before {*/
/*  background: black; !* Changes the background to black *!*/
/*}*/